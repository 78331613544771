import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { Terminal } from '~/ui/components/Snippet/blocks/Terminal';
import { P } from '~/ui/components/text';

export function ApplyUpdateSection() {
  return (
    <section className="mt-20 max-md-gutters:mt-10">
      <P className="mb-4 text-palette-green12 dark:text-palette-green7">
        Push app updates directly to your users.
      </P>
      <div className="grid grid-cols-[1fr_repeat(2,auto)] items-start gap-6 max-md-gutters:grid-cols-1">
        <Terminal
          cmd={['$ eas channel:edit', '  production --branch version-3.0']}
          cmdCopy="eas channel:edit"
        />
        <img
          src={getAssetUrl('/static/images/pages/eas/eas-update/send-update.svg')}
          className="-mt-4 max-w-[20vw] max-xl-gutters:hidden"
          alt="Send builds"
        />
        <img
          src={getAssetUrl('/static/images/pages/eas/eas-update/apply-update.svg')}
          className={mergeClasses(
            '-mt-24 max-w-[30vw]',
            'max-xl-gutters:-mt-16',
            'max-md-gutters:m-auto max-md-gutters:mt-0 max-md-gutters:max-w-none',
            'max-sm-gutters:max-w-[70vw]'
          )}
          alt="App Store"
        />
      </div>
    </section>
  );
}
