import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { MarketingWhiteButton } from '~/ui/components/Button/MarketingWhiteButton';
import { H2 } from '~/ui/components/text';

export function FooterSection() {
  return (
    <section
      className={mergeClasses(
        'relative mb-6 mt-20 overflow-hidden rounded-lg bg-[#663114] px-8 py-20',
        'max-md-gutters:mb-0 max-md-gutters:mt-16'
      )}>
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-submit/decoration.svg')}
        alt=""
        className={mergeClasses(
          'absolute -left-10 bottom-0 max-h-[280px] rotate-180',
          'max-lg-gutters:left-0 max-lg-gutters:max-h-none'
        )}
      />
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-submit/decoration.svg')}
        alt=""
        className="absolute -right-10 top-0 max-h-[280px] -scale-y-100 max-lg-gutters:hidden"
      />
      <div className="relative flex flex-col items-center justify-center gap-6">
        <H2 size="xl" className="text-center text-palette-white">
          Deliver your apps with ease
        </H2>
        <MarketingWhiteButton openInNewTab href="https://docs.expo.dev/submit/introduction">
          Get Started
        </MarketingWhiteButton>
      </div>
    </section>
  );
}
