import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { Terminal } from '~/ui/components/Snippet/blocks/Terminal';
import { P } from '~/ui/components/text';

export function SubmitBuildsSection() {
  return (
    <section className="mt-20 max-md-gutters:mt-10">
      <P className="mb-4 text-palette-orange12 dark:text-palette-orange7">
        Submit your builds with one command
      </P>
      <div className="grid grid-cols-[1fr_repeat(2,auto)] items-start gap-6 max-md-gutters:grid-cols-1">
        <Terminal
          cmd={[
            '$ eas submit',
            'Submitting to the Play Store…',
            'Submitting to the App Store…',
            'Submissions complete!',
          ]}
          cmdCopy="eas submit"
        />
        <img
          src={getAssetUrl('/static/images/pages/eas/eas-submit/send-builds.svg')}
          className="mt-2 max-w-[20vw] max-xl-gutters:hidden"
          alt="Send builds"
        />
        <img
          src={getAssetUrl('/static/images/pages/eas/eas-submit/app-store.svg')}
          className={mergeClasses(
            '-mt-14 max-w-[30vw]',
            'max-xl-gutters:-mt-10',
            'max-md-gutters:m-auto max-md-gutters:mt-0 max-md-gutters:max-w-none',
            'max-sm-gutters:max-w-[70vw]'
          )}
          alt="App Store"
        />
      </div>
    </section>
  );
}
