import { BracketsIcon } from '@expo/styleguide-icons/outline/BracketsIcon';
import { Cube01Icon } from '@expo/styleguide-icons/outline/Cube01Icon';
import { PasscodeLockIcon } from '@expo/styleguide-icons/outline/PasscodeLockIcon';

import { P } from '~/ui/components/text';

import { FeatureItem } from '../FeatureItem';

export function FeaturesSection() {
  return (
    <section className="mt-20 max-md-gutters:pt-0">
      <P className="mb-4 text-palette-white">A developer experience you'll love</P>
      <div className="grid grid-cols-3 gap-3 max-xl-gutters:grid-cols-auto-min-1">
        <FeatureItem
          icon={<BracketsIcon className="icon-lg text-palette-white" />}
          title="JSON configuration"
          description="Create multiple profiles capable of configuring builds for any environment."
        />
        <FeatureItem
          icon={<Cube01Icon className="icon-lg text-palette-white" />}
          title="Optimized binaries"
          description="We only include required libraries at compile time so that you'll have the smallest possible build."
        />
        <FeatureItem
          icon={<PasscodeLockIcon className="icon-lg text-palette-white" />}
          title="Secrets and credentials"
          description="We'll help you manage your secrets and credentials, so you can focus on building your app."
        />
      </div>
    </section>
  );
}
