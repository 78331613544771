import { AppleSimulatorIcon } from '@expo/styleguide-icons/custom/AppleSimulatorIcon';
import { EasSubmitIcon } from '@expo/styleguide-icons/custom/EasSubmitIcon';
import { Smartphone01Icon } from '@expo/styleguide-icons/custom/Smartphone01Icon';
import { Users01Icon } from '@expo/styleguide-icons/outline/Users01Icon';

import { P } from '~/ui/components/text';

import { FeatureItem } from '../FeatureItem';

export function BuildTypesSection() {
  return (
    <section className="mt-28 max-md-gutters:mt-14">
      <P className="mb-4 text-palette-white">
        Create builds for every step of your app’s development process
      </P>
      <div className="grid grid-cols-4 gap-3 max-xl-gutters:grid-cols-2 max-md-gutters:grid-cols-auto-min-1">
        <FeatureItem
          icon={<Smartphone01Icon className="icon-lg text-palette-white" />}
          title="Run on your device"
          description="with development builds"
        />
        <FeatureItem
          icon={<AppleSimulatorIcon className="icon-lg text-palette-white" />}
          title="Test locally"
          description="with simulator builds"
        />
        <FeatureItem
          icon={<Users01Icon className="icon-lg text-palette-white" />}
          title="Share with your team"
          description="with internal distribution"
        />
        <FeatureItem
          icon={<EasSubmitIcon className="icon-lg text-palette-white" />}
          title="Ready for users"
          description="with app store builds"
        />
      </div>
    </section>
  );
}
