import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { MarketingWhiteButton } from '~/ui/components/Button/MarketingWhiteButton';
import { H2 } from '~/ui/components/text';

export function FooterSection() {
  return (
    <section
      className={mergeClasses(
        'relative mb-6 mt-20 overflow-hidden rounded-lg bg-[#18794e] px-8 py-20',
        'max-md-gutters:mb-0 max-md-gutters:mt-16'
      )}>
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-update/footer-bg.svg')}
        alt="EAS Update footer background pattern"
        className={mergeClasses(
          'absolute -bottom-32 left-1/2 -ml-[50%] opacity-50',
          'max-lg-gutters:-bottom-8',
          'max-md-gutters:-bottom-2',
          'max-sm-gutters:bottom-10 max-sm-gutters:scale-200'
        )}
      />
      <div className="relative flex flex-col items-center justify-center gap-6">
        <H2 size="xl" className="text-center text-palette-white">
          Don't wait to ship your next app update
        </H2>
        <MarketingWhiteButton openInNewTab href="https://docs.expo.dev/eas-update/introduction/">
          Get Started
        </MarketingWhiteButton>
      </div>
    </section>
  );
}
