import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { H2 } from '~/ui/components/text';

import { File } from './File';

export function NativeCodeSection() {
  return (
    <section
      className={mergeClasses(
        'relative mb-14 mt-24 flex min-h-[550px] items-center justify-center',
        'max-sm-gutters:min-h-[500px]'
      )}>
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-build/native-code-decoration.svg')}
        className="absolute left-0 max-w-[50vw] select-none"
        alt=""
      />
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-build/native-code-decoration.svg')}
        className="absolute right-0 max-w-[50vw] rotate-180 select-none"
        alt=""
      />
      <File
        className="left-[150px] top-[50px] max-lg-gutters:left-[50px] max-sm-gutters:left-[16px]"
        fileName="AppDelegate.m"
      />
      <File
        className="left-[50px] top-[110px] max-lg-gutters:left-[100px] max-sm-gutters:left-[32px]"
        fileName="expo-in-app-purchases"
        type="library"
      />
      <File
        className="right-[150px] top-[50px] max-lg-gutters:hidden"
        fileName="react-native-webrtc"
        type="library"
      />
      <File className="right-[50px] top-[110px] max-lg-gutters:hidden" fileName="build.gradle" />
      <File
        className="bottom-[110px] left-[50px] max-lg-gutters:hidden"
        fileName="react-native-ble-plx"
        type="library"
      />
      <File className="bottom-[50px] left-[175px] max-lg-gutters:hidden" fileName="App.tsx" />
      <File
        className="bottom-[110px] right-[50px] max-lg-gutters:right-[100px] max-sm-gutters:right-[32px]"
        fileName="AndroidManifest.xml"
      />
      <File
        className="bottom-[50px] right-[150px] max-lg-gutters:right-[50px] max-sm-gutters:right-[16px]"
        fileName="react-native-blurhash"
        type="library"
      />
      <H2
        size="xl"
        weight="semiBold"
        className={mergeClasses(
          'relative my-4 bg-native-code-gradient bg-clip-text text-center !text-[53px] !text-transparent',
          'max-md-gutters:max-w-[85vw] max-sm-gutters:!text-[31px]'
        )}>
        All libraries and code welcome.
        <br />
        Native ones too.
      </H2>
    </section>
  );
}
