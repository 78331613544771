import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { MarketingWhiteButton } from '~/ui/components/Button/MarketingWhiteButton';
import { H1, H5 } from '~/ui/components/text';

export function Splash() {
  return (
    <div
      className={mergeClasses(
        'relative my-20 flex items-center justify-center',
        'max-md-gutters:my-0'
      )}>
      <img
        src={getAssetUrl('/static/images/pages/eas/splash/objects.svg')}
        className={mergeClasses(
          'absolute z-10 h-[733px] animate-easSplashObjectsEntry opacity-0',
          'max-xl-gutters:h-[587px] max-xl-gutters:max-w-[95%]',
          'max-md-gutters:hidden'
        )}
        alt="Splash Objects"
      />
      <div className="h-[553px] w-full rounded-xl bg-palette-black">
        <img
          src={getAssetUrl('/static/images/pages/eas/splash/background.svg')}
          className={mergeClasses(
            'h-full max-w-full animate-easSplashFadeIn rounded-xl object-cover opacity-0',
            'max-md-gutters:rounded-none'
          )}
          alt="Splash Background"
        />
      </div>
      <div className="absolute z-20 flex flex-col items-center justify-center px-4">
        <img
          width="72"
          height="76"
          src={getAssetUrl('/static/images/pages/eas/splash/icon.svg')}
          className="max-h-[80px] object-contain max-sm-gutters:max-h-[64px]"
          alt="EAS icon"
        />
        <H1
          size="xl"
          className={mergeClasses(
            'mb-4 mt-6 text-center text-palette-white',
            'max-sm-gutters:text-[31px] max-sm-gutters:leading-tight'
          )}>
          Expo Application Services
        </H1>
        <H5
          size="xl"
          weight="regular"
          tag="p"
          className="mb-10 max-w-[40ch] text-center text-palette-white">
          Deeply integrated cloud services for Expo and React Native apps, from the team behind
          Expo.
        </H5>
        <MarketingWhiteButton href="/pricing">Learn More</MarketingWhiteButton>
      </div>
    </div>
  );
}
