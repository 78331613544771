import { Certificate01Icon } from '@expo/styleguide-icons/outline/Certificate01Icon';
import { Dataflow04Icon } from '@expo/styleguide-icons/outline/Dataflow04Icon';
import { Star07Icon } from '@expo/styleguide-icons/outline/Star07Icon';

import { P } from '~/ui/components/text';

import { FeatureItem } from '../FeatureItem';

export function FeaturesSection() {
  return (
    <section className="mt-20 max-md-gutters:pt-0">
      <P className="mb-4 text-palette-green12 dark:text-palette-green7">The power of EAS Update</P>
      <div className="dark-theme grid grid-cols-3 gap-3 max-xl-gutters:grid-cols-auto-min-1">
        <FeatureItem
          icon={<Dataflow04Icon className="icon-lg text-palette-green8" />}
          title="Enhanced Workflow"
          description="Build developer momentum with a culture of shipping work continuously."
          className="bg-[#fbfefc]"
          textClassName="text-palette-green2"
        />
        <FeatureItem
          icon={<Star07Icon className="icon-lg text-palette-green8" />}
          title="End User Experience"
          description="Release hotfixes and enhancements in response to user feedback or market trends."
          className="bg-[#fbfefc]"
          textClassName="text-palette-green2"
        />
        <FeatureItem
          icon={<Certificate01Icon className="icon-lg text-palette-green8" />}
          title="Broad Compatibility"
          description="EAS Update is designed for Expo apps & works with pure React Native apps."
          className="bg-[#fbfefc]"
          textClassName="text-palette-green2"
        />
      </div>
    </section>
  );
}
