import { LogsIcon } from '@expo/styleguide-icons/custom/LogsIcon';
import { BracketsIcon } from '@expo/styleguide-icons/outline/BracketsIcon';
import { PlaySquareIcon } from '@expo/styleguide-icons/outline/PlaySquareIcon';

import { P } from '~/ui/components/text';

import { FeatureItem } from '../FeatureItem';

export function FeaturesSection() {
  return (
    <section className="mt-20 max-md-gutters:pt-0">
      <P className="mb-4 text-palette-orange12 dark:text-palette-orange4">
        Set up and keep track of submissions like never before
      </P>
      <div className="dark-theme grid grid-cols-3 gap-3 max-xl-gutters:grid-cols-auto-min-1">
        <FeatureItem
          icon={<PlaySquareIcon className="icon-lg text-palette-orange8" />}
          title="Automated"
          description="Automatically submit your builds when they finish building."
          className="bg-[#fef8f4]"
          textClassName="text-palette-orange2"
        />
        <FeatureItem
          icon={<BracketsIcon className="icon-lg text-palette-orange8" />}
          title="JSON configuration"
          description="Create multiple submission profiles capable of submitting builds to app stores."
          className="bg-[#fef8f4]"
          textClassName="text-palette-orange2"
        />
        <FeatureItem
          icon={<LogsIcon className="icon-lg text-palette-orange8" />}
          title="Descriptive logs"
          description="View formatted logs and errors, all saved and accessible by your team."
          className="bg-[#fef8f4]"
          textClassName="text-palette-orange2"
        />
      </div>
    </section>
  );
}
