import { EASLandingPage } from '~/scenes/EASLandingPage';
import { PageProps } from '~/scenes/_app/helpers';
import Document from '~/ui/components/Document';

export default function EASPage({ currentUser }: PageProps) {
  return (
    <Document meta={{ name: 'eas' }}>
      <EASLandingPage currentUser={currentUser} />
    </Document>
  );
}
