import { DocsLogo } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';

import { getAssetUrl } from '~/common/utilities';
import { Button } from '~/ui/components/Button';
import { Terminal } from '~/ui/components/Snippet/blocks/Terminal';
import { H2, H4, H5, P } from '~/ui/components/text';

import { Section } from './Section';

export function GetStarted() {
  return (
    <Section
      icon={
        <img
          src={getAssetUrl('/static/images/pages/eas/get-started/icon.svg')}
          alt="Get started icon"
        />
      }
      className="bg-[#f5faff] dark:bg-[#d4e9ff]"
      lineClassName="bg-[#96c7f2]">
      <div>
        <H2 size="xl" className="mb-2 font-bold text-palette-blue12 dark:text-palette-blue1">
          It all starts with your project
        </H2>
        <H5
          size="xl"
          className="max-w-[40ch] text-palette-blue12 opacity-60 dark:text-palette-blue1">
          Create genuine native apps from the same JavaScript/TypeScript codebase.
        </H5>
        <P className="mb-4 mt-8 text-palette-blue12 opacity-60 dark:text-palette-blue2">
          Start an Expo project in less than five minutes
        </P>
        <div className="relative rounded-lg bg-palette-blue4 dark:bg-[#b4d8fe]">
          <div className="relative z-10 p-6">
            <H4
              size="xl"
              weight="semiBold"
              className="mb-6 max-w-[22ch] text-palette-blue12 dark:text-palette-blue2">
              Create a universal Android, iOS and web application
            </H4>
            <Button
              size="lg"
              href="https://docs.expo.dev/tutorial/introduction/"
              openInNewTab
              rightSlot={<ArrowUpRightIcon />}>
              Start Tutorial
            </Button>
          </div>
          <img
            src={getAssetUrl('/static/images/pages/eas/get-started/photo-sharing-app.svg')}
            className="absolute bottom-0 right-0 max-md-gutters:opacity-20"
            alt="Photo sharing app"
          />
        </div>
        <P className="mb-4 mt-8 text-palette-blue12 opacity-60 dark:text-palette-blue2">
          Or run this command get started right now
        </P>
        <Terminal cmd={['$ npm install --global eas-cli && npx create-expo-app']} />
        <Button
          openInNewTab
          size="lg"
          href="https://docs.expo.dev"
          leftSlot={<DocsLogo className="text-palette-white" />}
          rightSlot={<ArrowUpRightIcon className="icon-sm" />}
          className="mt-8 border-palette-black bg-palette-black text-palette-white hocus:bg-palette-black hocus:opacity-80">
          Get Started with Docs
        </Button>
      </div>
    </Section>
  );
}
