import { mergeClasses } from '@expo/styleguide';
import { ReactElement } from 'react';

import { LABEL, CALLOUT } from '~/ui/components/text';

type Props = {
  icon: ReactElement;
  title: string;
  description: string;
  className?: string;
  textClassName?: string;
};

export function FeatureItem({
  icon,
  title,
  description,
  className = 'bg-[#34404B]',
  textClassName = 'text-palette-white',
}: Props) {
  return (
    <div
      className={mergeClasses(
        'flex flex-col items-center justify-center rounded-lg p-6 text-center',
        'max-xl-gutters:flex-row max-xl-gutters:items-start max-xl-gutters:justify-start max-xl-gutters:gap-3 max-xl-gutters:text-left',
        className
      )}>
      <div>{icon}</div>
      <div>
        <LABEL className={mergeClasses('mt-4 block', 'mb-0.5 max-xl-gutters:mt-0', textClassName)}>
          {title}
        </LABEL>
        <CALLOUT className={mergeClasses('opacity-80', textClassName)}>{description}</CALLOUT>
      </div>
    </div>
  );
}
