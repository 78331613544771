import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { LinkBase } from '~/ui/components/LinkBase';
import { H2, H5 } from '~/ui/components/text';

import { BuildCommandSection } from './BuildCommandSection';
import { BuildTypesSection } from './BuildTypesSection';
import { FeaturesSection } from './FeaturesSection';
import { FooterSection } from './FooterSection';
import { NativeCodeSection } from './NativeCodeSection';
import { Section } from '../Section';

export function EASBuild() {
  return (
    <Section
      icon={<img src={getAssetUrl('/static/images/pages/eas/eas-build/icon.svg')} alt="" />}
      className="bg-[#19232D]"
      lineClassName="bg-[#CEE7FE]">
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-build/decoration.svg')}
        className={mergeClasses(
          'absolute right-0 top-0 opacity-80 transition-opacity',
          'max-xl-gutters:opacity-20',
          'max-md-gutters:opacity-10'
        )}
        alt=""
      />
      <div className="relative">
        <LinkBase href="#build">
          <H2 size="xl" id="build" className="mb-3 scroll-m-8 font-bold text-palette-white">
            EAS Build
          </H2>
        </LinkBase>
        <H5 size="xl" className="max-w-[40ch] text-palette-white">
          Compile and sign Android/iOS apps with custom native code in the cloud.
        </H5>
        <BuildCommandSection />
        <BuildTypesSection />
        <NativeCodeSection />
        <FeaturesSection />
        <FooterSection />
      </div>
    </Section>
  );
}
