import { getAssetUrl } from '~/common/utilities';
import { Terminal } from '~/ui/components/Snippet/blocks/Terminal';
import { P, HEADLINE } from '~/ui/components/text';

export function AutoSubmitSection() {
  return (
    <section className="relative mt-20 rounded-lg bg-palette-orange5 p-6 pl-14 dark:bg-[#ffdcc3]">
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-submit/flag.svg')}
        className="absolute -top-6 left-4"
        alt="flag"
      />
      <div className="grid grid-cols-2 items-center gap-6 max-lg-gutters:grid-cols-1">
        <div>
          <HEADLINE className="mb-1.5 text-palette-orange12 dark:text-palette-orange3">
            Shortcut
          </HEADLINE>
          <P className="text-palette-orange12 dark:text-palette-orange7">
            When your build finishes, don’t wait to run a command. We’ll run it for you. At last,
            “kicking off a build” means taking your code all the way to the store.
          </P>
        </div>
        <div>
          <Terminal cmd={['$ eas build --auto-submit']} />
        </div>
      </div>
    </section>
  );
}
