import { mergeClasses } from '@expo/styleguide';

import { getAssetUrl } from '~/common/utilities';
import { LinkBase } from '~/ui/components/LinkBase';
import { H2, H5 } from '~/ui/components/text';

import { ApplyUpdateSection } from './ApplyUpdateSection';
import { FeaturesSection } from './FeaturesSection';
import { FooterSection } from './FooterSection';
import { RolloutSection } from './RolloutSection';
import { Section } from '../Section';

export function EASUpdate() {
  return (
    <Section
      icon={
        <img
          src={getAssetUrl('/static/images/pages/eas/eas-update/icon.svg')}
          alt="EAS Submit icon"
        />
      }
      className="overflow-hidden bg-[#ddf3e4]"
      lineClassName="bg-[#5bb98c]">
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-update/footer-bg.svg')}
        className={mergeClasses(
          'absolute -right-24 -top-14 max-w-[55%] rotate-180 opacity-30',
          'max-md-gutters:-right-4 max-md-gutters:-top-4 max-md-gutters:max-w-[66%]',
          'max-sm-gutters:max-w-[80%]'
        )}
        alt="Background decoration"
      />
      <div className="relative">
        <LinkBase href="#update">
          <H2
            size="xl"
            id="update"
            className="mb-3 scroll-m-8 font-bold text-palette-green12 dark:text-palette-green3">
            EAS Update
          </H2>
        </LinkBase>
        <H5
          size="xl"
          className="mb-3 max-w-[40ch] text-palette-green12 opacity-60 dark:text-palette-green3">
          Seamlessly deliver live app updates, critical bug fixes, content changes, beta features,
          and more to give your users the best experience possible.
        </H5>
        <ApplyUpdateSection />
        <RolloutSection />
        <FeaturesSection />
        <FooterSection />
      </div>
    </Section>
  );
}
