import { mergeClasses } from '@expo/styleguide';
import type { PropsWithChildren, ReactElement } from 'react';

type Props = PropsWithChildren<{
  icon: ReactElement;
  lineClassName?: string;
  className?: string;
}>;

export function Section({ children, icon, lineClassName, className }: Props) {
  return (
    <div
      className={mergeClasses(
        'relative grid max-w-screen-xl grid-cols-[auto_1fr] gap-6 rounded-xl p-6 pr-14',
        'max-md-gutters:grid-cols-1 max-md-gutters:gap-0 max-md-gutters:rounded-none max-md-gutters:px-4 max-md-gutters:py-12',
        className
      )}>
      <div
        className={mergeClasses(
          'absolute inset-y-0 left-14 z-0 w-1 max-md-gutters:hidden',
          lineClassName ? lineClassName : 'bg-palette-gray5'
        )}
      />
      <div className="z-[1] max-w-full">{icon}</div>
      <div className="mb-4 mt-2">{children}</div>
    </div>
  );
}
