import { AndroidIcon } from '@expo/styleguide-icons/custom/AndroidIcon';
import { AppleIcon } from '@expo/styleguide-icons/custom/AppleIcon';

import { getAssetUrl } from '~/common/utilities';
import { BuildStubEntry } from '~/scenes/EASLandingPage/EASBuild/BuildStubEntry';
import { Terminal } from '~/ui/components/Snippet/blocks/Terminal';
import { Tag } from '~/ui/components/Tag';
import { P, LABEL } from '~/ui/components/text';

export function BuildCommandSection() {
  return (
    <section className="mt-16">
      <P className="mb-4 text-palette-white">
        Get your project into a store-ready build with just one command
      </P>
      <Terminal
        cmd={[
          '$ eas build',
          'Building apps…',
          'View your build progress at https://expo.dev/accounts/…',
        ]}
        cmdCopy="eas build"
        className="relative z-[1] mb-6 max-w-[500px]"
      />
      <div className="flex gap-12">
        <img
          src={getAssetUrl('/static/images/pages/eas/eas-build/command-arrows.svg')}
          alt=""
          className="relative -top-16 left-6 max-md-gutters:hidden"
        />
        <div className="flex flex-1 flex-col gap-6">
          <BuildStubEntry
            header={
              <div className="flex items-center gap-2 p-4">
                <AndroidIcon />
                <LABEL>Android Play Store build</LABEL>
                <Tag small title="AAB" />
              </div>
            }
            totalTime="8m 50s"
          />
          <BuildStubEntry
            header={
              <div className="flex items-center gap-2 p-4">
                <AppleIcon />
                <LABEL>iOS App Store build</LABEL>
                <Tag small title="IPA" />
              </div>
            }
            totalTime="7m 44s"
          />
        </div>
      </div>
    </section>
  );
}
