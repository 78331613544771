import type { ReactElement } from 'react';

import { BuildStatus } from '~/graphql/types.generated';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { StatusIndicator } from '~/ui/components/StatusIndicator';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableCellText } from '~/ui/components/Table/TableCellText';
import { TableHeader } from '~/ui/components/Table/TableHeader';
import { TableHeaderCell } from '~/ui/components/Table/TableHeaderCell';
import { TableHeaderCellText } from '~/ui/components/Table/TableHeaderCellText';
import { TableRoot } from '~/ui/components/Table/TableRoot';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  header: ReactElement;
  totalTime: string;
};

export function BuildStubEntry({ header, totalTime }: Props) {
  return (
    <BoxWrapper className="!rounded-lg bg-overlay dark:bg-screen">
      {header}
      <TableRoot columnCount={3} gridTemplateColumns={['auto', 'auto', '1fr']} theme="unstyled">
        <TableSeparator />
        <TableHeader theme="secondary">
          <TableHeaderCell>
            <TableHeaderCellText>Status</TableHeaderCellText>
          </TableHeaderCell>
          <TableHeaderCell>
            <TableHeaderCellText>Start time</TableHeaderCellText>
          </TableHeaderCell>
          <TableHeaderCell>
            <TableHeaderCellText>Total time</TableHeaderCellText>
          </TableHeaderCell>
        </TableHeader>
        <TableSeparator />
        <TableRow>
          <TableCell className="flex min-h-[52px] items-center gap-1.5">
            <StatusIndicator status={BuildStatus.Finished} />
            <CALLOUT>Finished</CALLOUT>
          </TableCell>
          <TableCell>
            <TableCellText>Jan 11, 2023 7:07PM</TableCellText>
          </TableCell>
          <TableCell>
            <TableCellText>{totalTime}</TableCellText>
          </TableCell>
        </TableRow>
      </TableRoot>
    </BoxWrapper>
  );
}
