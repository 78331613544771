import { mergeClasses } from '@expo/styleguide';
import { Dataflow01DuotoneIcon } from '@expo/styleguide-icons/duotone/Dataflow01DuotoneIcon';

import { Terminal } from '~/ui/components/Snippet/blocks/Terminal';
import { P, HEADLINE, CODE } from '~/ui/components/text';

export function RolloutSection() {
  return (
    <section className="relative mt-20 rounded-lg bg-palette-green5 p-6 dark:bg-[#ccebd7]">
      <div className="grid grid-cols-2 items-center gap-6 max-lg-gutters:grid-cols-1">
        <div>
          <HEADLINE className="mb-1.5 flex items-center gap-2 text-palette-green12 dark:text-palette-green3">
            <Dataflow01DuotoneIcon className="text-palette-green8" />
            Customize your update strategies
          </HEADLINE>
          <P className="text-palette-green12 dark:text-palette-green7">
            Choose when to apply new updates through{' '}
            <CODE
              className={mergeClasses(
                'border-0 bg-palette-green3 text-palette-green12',
                'dark:bg-palette-green12 dark:text-palette-green4'
              )}>
              expo-updates
            </CODE>{' '}
            API and app config if the default behavior is not suitable for you. You’ll always have
            the upper hand in shaping the update process without compromising your users'
            experience.
          </P>
        </div>
        <div>
          <Terminal cmd={['$ eas channel:rollout']} />
        </div>
      </div>
    </section>
  );
}
