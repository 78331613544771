import { mergeClasses } from '@expo/styleguide';
import { GithubIcon } from '@expo/styleguide-icons/custom/GithubIcon';
import { File04Icon } from '@expo/styleguide-icons/outline/File04Icon';

import { P } from '~/ui/components/text';

type Props = {
  type?: 'file' | 'library';
  fileName: string;
  className?: string;
};

export function File({ type = 'file', fileName, className }: Props) {
  return (
    <div
      className={mergeClasses(
        'absolute flex items-center gap-2 rounded-lg bg-[#26303a] px-3 py-1.5 shadow-md',
        className
      )}>
      {type === 'file' ? <File04Icon className="icon-sm" /> : <GithubIcon className="icon-sm" />}
      <P className="break-keep text-palette-white">{fileName}</P>
    </div>
  );
}
