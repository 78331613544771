import { getAssetUrl } from '~/common/utilities';
import { LinkBase } from '~/ui/components/LinkBase';
import { H2, H5 } from '~/ui/components/text';

import { AutoSubmitSection } from './AutoSubmitSection';
import { FeaturesSection } from './FeaturesSection';
import { FooterSection } from './FooterSection';
import { SubmitBuildsSection } from './SubmitBuildsSection';
import { Section } from '../Section';

export function EASSubmit() {
  return (
    <Section
      icon={
        <img
          src={getAssetUrl('/static/images/pages/eas/eas-submit/icon.svg')}
          alt="EAS Submit icon"
        />
      }
      className="overflow-hidden bg-[#ffe8d7]"
      lineClassName="bg-[#FFB381]">
      <img
        src={getAssetUrl('/static/images/pages/eas/eas-submit/decoration.svg')}
        className="absolute right-0 top-0"
        alt="Background decoration"
      />
      <div className="relative">
        <LinkBase href="#submit">
          <H2
            size="xl"
            id="submit"
            className="mb-3 scroll-m-8 font-bold text-palette-orange12 dark:text-palette-orange3">
            EAS Submit
          </H2>
        </LinkBase>
        <H5
          size="xl"
          className="mb-3 max-w-[40ch] text-palette-orange12 opacity-60 dark:text-palette-orange3">
          Upload your app to the Play Store or App Store from the cloud with a single command.
        </H5>
        <SubmitBuildsSection />
        <AutoSubmitSection />
        <FeaturesSection />
        <FooterSection />
      </div>
    </Section>
  );
}
