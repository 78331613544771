import { PageProps } from '~/scenes/_app/helpers';
import { EASCallToAction } from '~/ui/components/EAS/EASCallToAction';
import { EASMailingList } from '~/ui/components/EAS/EASMailingList';
import Content from '~/ui/patterns/layouts/Content';

import { EASBuild } from './EASBuild';
import { EASSubmit } from './EASSubmit';
import { EASUpdate } from './EASUpdate';
import { GetStarted } from './GetStarted';
import { Splash } from './Splash';

type Props = Pick<PageProps, 'currentUser'>;

export function EASLandingPage({ currentUser }: Props) {
  return (
    <div className="bg-default">
      <Content className="!max-w-screen-xl !pt-6 max-md-gutters:!p-0">
        <div className="relative flex flex-col gap-20 max-md-gutters:gap-0">
          <div className="absolute bottom-[100px] left-14 top-[200px] z-0 w-1 bg-palette-gray5 max-md-gutters:hidden" />
          <Splash />
          <GetStarted />
          <EASBuild />
          <EASSubmit />
          <EASUpdate />
          <EASCallToAction
            title="Get there faster with Expo Application Services"
            href="/pricing"
            buttonText="Learn More"
            currentUser={currentUser}
          />
        </div>
        <div className="my-20">
          <EASMailingList email={currentUser?.bestContactEmail} />
        </div>
      </Content>
    </div>
  );
}
